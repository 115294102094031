.link {
  div.link-block {
    padding: 8px;
    box-sizing: border-box;
    // border: solid 1px $lightGray;
    border-radius: 8px;
    &:hover {
      cursor: pointer;
      text-decoration: none;
      background-color: gray;
    }
  }
}