body {
  background-color: #1f1f1f;
}

a {
  color: #aaaaaa;
  transition: color 0.1s ease-in-out;
  &:hover {
    color: #ffffff;
  }
}